import React, { ReactElement } from "react";

export default function ConnectIcon(): ReactElement {
    return (
        <svg
            width="93"
            height="80"
            viewBox="0 0 93 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_135_385_1)">
                <path
                    d="M38.0949 37.6373L35.2016 40.5059C34.6576 41.05 34.1135 41.6188 33.5447 42.1381C33.4625 42.1728 33.3742 42.1906 33.285 42.1906C33.1958 42.1906 33.1076 42.1728 33.0254 42.1381C32.3778 41.8789 31.6666 41.8229 30.9863 41.9775C30.3061 42.1321 29.689 42.4899 29.2171 43.0036L13.86 58.3606C13.4768 58.6762 13.1681 59.0726 12.9562 59.5216C12.7442 59.9705 12.6343 60.4608 12.6343 60.9572C12.6343 61.4537 12.7442 61.944 12.9562 62.3929C13.1681 62.8419 13.4768 63.2383 13.86 63.5538C16.6792 66.406 19.4983 69.2251 22.3175 72.0113C22.6353 72.3994 23.0353 72.7121 23.4886 72.9268C23.9419 73.1416 24.4372 73.2529 24.9388 73.2529C25.4404 73.2529 25.9358 73.1416 26.3891 72.9268C26.8424 72.7121 27.2424 72.3994 27.5601 72.0113L42.8183 56.7779C43.447 56.2517 43.8618 55.5139 43.9848 54.7033C44.1078 53.8927 43.9303 53.0651 43.486 52.3761L48.2341 47.6775C48.5061 48.0484 48.8276 48.4194 49.0996 48.8398C50.3703 50.7586 50.9322 53.0605 50.6884 55.349C50.4447 57.6376 49.4106 59.7695 47.7642 61.3776C42.5463 66.645 37.3036 71.863 32.0609 77.1056C31.1389 78.0553 30.0357 78.8104 28.8165 79.326C27.5974 79.8415 26.2872 80.1072 24.9636 80.1072C23.6399 80.1072 22.3297 79.8415 21.1106 79.326C19.8915 78.8104 18.7882 78.0553 17.8662 77.1056C14.8987 74.1381 11.9064 71.1705 8.96357 68.1783C8.02519 67.2574 7.27976 66.1588 6.77091 64.9465C6.26206 63.7342 6 62.4327 6 61.118C6 59.8033 6.26206 58.5017 6.77091 57.2895C7.27976 56.0772 8.02519 54.9785 8.96357 54.0577C14.2062 48.7656 19.4737 43.5064 24.7658 38.2803C26.4394 36.5916 28.6652 35.5621 31.0358 35.3805C33.4065 35.1988 35.7632 35.8771 37.6746 37.2911L38.0949 37.6373Z"
                    fill="white"
                />
                <path
                    d="M53.6499 42.2106L58.3733 37.4873C58.7776 37.7729 59.2432 37.9598 59.7328 38.0328C60.2224 38.1058 60.7223 38.0629 61.1924 37.9077C61.7198 37.7216 62.2067 37.4362 62.6267 37.0669C67.8694 31.8572 73.112 26.6146 78.3547 21.3389C78.9723 20.718 79.319 19.8778 79.319 19.002C79.319 18.1262 78.9723 17.286 78.3547 16.665C75.3871 13.648 72.3702 10.6558 69.3532 7.66349C69.0448 7.35021 68.6772 7.1014 68.2717 6.93157C67.8663 6.76174 67.431 6.67428 66.9915 6.67428C66.5519 6.67428 66.1167 6.76174 65.7112 6.93157C65.3058 7.1014 64.9382 7.35021 64.6298 7.66349L49.0749 23.3915C48.5927 23.8476 48.261 24.4399 48.124 25.0894C47.9871 25.7389 48.0513 26.4147 48.3083 27.0267C48.346 27.1081 48.3655 27.1967 48.3655 27.2864C48.3655 27.376 48.346 27.4646 48.3083 27.546L43.7333 32.121L43.3624 31.6511C41.9484 29.7398 41.2701 27.383 41.4518 25.0124C41.6334 22.6418 42.6629 20.4159 44.3516 18.7423C47.2944 15.75 50.2619 12.8072 53.2295 9.83969L60.0548 3.01435C60.9772 2.06081 62.082 1.30256 63.3034 0.784712C64.5248 0.266864 65.8379 0 67.1646 0C68.4912 0 69.8044 0.266864 71.0258 0.784712C72.2472 1.30256 73.3519 2.06081 74.2743 3.01435C77.2913 5.98189 80.2836 8.97416 83.2512 11.9912C84.1746 12.9103 84.9073 14.0028 85.4073 15.2059C85.9073 16.409 86.1647 17.6991 86.1647 19.002C86.1647 20.3049 85.9073 21.5949 85.4073 22.798C84.9073 24.0012 84.1746 25.0937 83.2512 26.0128C77.992 31.3379 72.7081 36.6218 67.3995 41.8644C65.6589 43.616 63.3261 44.6532 60.8596 44.7721C58.3932 44.8911 55.9714 44.0833 54.0703 42.5074L53.6499 42.2106Z"
                    fill="white"
                />
                <path
                    d="M27.1148 55.4427C27.0563 54.837 27.1324 54.2258 27.3376 53.653C27.5428 53.0801 27.8721 52.5597 28.3019 52.1289L49.0252 31.4056L58.1504 22.2804C58.6215 21.7648 59.2321 21.397 59.908 21.2214C60.584 21.0459 61.2963 21.0701 61.9587 21.2912C62.5775 21.4724 63.1349 21.8193 63.5708 22.2944C64.0067 22.7695 64.3043 23.3547 64.4317 23.9867C64.5961 24.607 64.5911 25.26 64.4171 25.8777C64.2431 26.4954 63.9065 27.055 63.4425 27.4983C60.5574 30.4164 57.6558 33.318 54.7377 36.2031C47.7146 43.2263 40.6666 50.2495 33.6681 57.2974C33.0633 57.984 32.2355 58.435 31.3306 58.5707C30.4257 58.7065 29.5019 58.5182 28.7222 58.0393C28.2749 57.7568 27.8977 57.3762 27.6192 56.9263C27.3407 56.4764 27.1682 55.9691 27.1148 55.4427V55.4427Z"
                    fill="#FF8A00"
                />
            </g>
            <defs>
                <clipPath id="clip0_135_385_1">
                    <rect width="93" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
