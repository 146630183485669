import React, { ReactElement } from "react";

import ConnectIcon from "../icons/connectIcon";
import MatchYouWithTheBestCompaniesIcon from "../icons/matchYouWithTheBestCompaniesIcon";
import CreditRepairEasierIcon from "../icons/creditRepairEasierIcon";
import FreeOfChargeIcon from "../icons/freeOfChargeIcon";
import BestFinancialSolutionsIcon from "../icons/bestFinancialSolutionsIcon";
import CreditScoreIcon from "../icons/creditScoreIcon";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "We Can Help You No Matter How Bad Your Credit Score Is!",
        icon: <CreditScoreIcon />,
    },
    {
        title: "We Connect You to Companies for Free!",
        icon: <ConnectIcon />,
    },
    {
        title: "We Match You With the Best Companies!",
        icon: <MatchYouWithTheBestCompaniesIcon />,
    },
    {
        title: "We Make Credit Repair Easier Than Ever!",
        icon: <CreditRepairEasierIcon />,
    },
    {
        title: "The Consultation Is Free of Charge!",
        icon: <FreeOfChargeIcon />,
    },
    {
        title: "Get the Best Help for Your Credit Score!",
        icon: <BestFinancialSolutionsIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle={
                <>
                    <span style={{ color: "#ff8a00" }}>Why</span>
                    FreeCreditEvaluation.net
                </>
            }
            classes={{
                mainTitleClasses:
                    "text-center text-2xl lg:text-4xl mb-14 font-bold",
                mainSectionClasses:
                    "max-w-7xl mx-auto block px-4vw lg:px-4 pt-20 pb-16",
                innerSectionClasses: "lg:flex flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: "children:mx-auto",
                titleClasses:
                    "font-medium text-lg lg:text-xl mt-6 font-light mx-auto max-w-[220px]",
            }}
            colors={{
                bgColor: "#063668",
                mainTitleColor: "white",
                titleColor: "white",
            }}
        />
    );
}
