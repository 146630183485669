import React, { ReactElement } from "react";

export default function MatchYouWithTheBestCompaniesIcon(): ReactElement {
    return (
        <svg
            width="93"
            height="80"
            viewBox="0 0 93 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_135_385_2)">
                <mask id="path-1-inside-1_135_385" fill="white">
                    <rect x="11" y="16" width="41" height="61" rx="3" />
                </mask>
                <rect
                    x="11"
                    y="16"
                    width="41"
                    height="61"
                    rx="3"
                    stroke="#FF8A00"
                    strokeWidth="8"
                    mask="url(#path-1-inside-1_135_385)"
                />
                <path
                    d="M44 5H80C81.1046 5 82 5.89543 82 7V63"
                    stroke="#FF8A00"
                    strokeWidth="4"
                />
                <path
                    d="M10.7168 15.6079H41.7771V3.70505C41.7771 2.67576 42.1957 1.74217 42.8702 1.06835C43.5283 0.409502 44.4294 0 45.4111 0H83.2774C84.2591 0 85.1595 0.408851 85.8177 1.06835C86.4915 1.74282 86.9102 2.67576 86.9102 3.70505V60.6864C86.9102 61.7163 86.4915 62.6499 85.8177 63.3237C85.1589 63.9826 84.2585 64.3921 83.2774 64.3921H52.2164V76.295C52.2164 77.3249 51.7965 78.2585 51.1227 78.9323C50.4638 79.5912 49.5635 80.0007 48.5823 80.0007H10.7168C9.73435 80.0007 8.83462 79.5912 8.17577 78.9323C7.5013 78.2585 7.08268 77.3249 7.08268 76.295V48.4299C7.02865 48.2301 7 48.0204 7 47.8043C7 47.5869 7.02865 47.3772 7.08268 47.1773V19.3136C7.08268 18.2837 7.5013 17.3501 8.17577 16.6763C8.83462 16.0174 9.73435 15.6079 10.7168 15.6079ZM68.271 18.9692C69.1792 18.0135 70.6909 17.9764 71.646 18.8853C72.6023 19.7941 72.6388 21.3058 71.7299 22.2609L64.6258 29.6931H82.2891V4.76494H46.3988V15.6079H48.5823C49.5641 15.6079 50.4645 16.0168 51.1227 16.6763C51.7965 17.3501 52.2164 18.2837 52.2164 19.3136V59.6271H82.2884V34.4808H64.6128L71.7299 41.9521C72.6388 42.9085 72.6023 44.4189 71.646 45.3284C70.6909 46.2373 69.1792 46.1995 68.271 45.2444L57.3056 33.7335C56.4332 32.8174 56.4241 31.3676 57.3056 30.4412L68.271 18.9692ZM21.9367 37.9769C21.0279 37.0212 21.0656 35.5101 22.0207 34.6013C22.9764 33.6924 24.4875 33.7302 25.3963 34.6853L36.3617 46.1572C37.2432 47.0843 37.2341 48.5335 36.3611 49.4495L25.3963 60.9611C24.4875 61.9162 22.9764 61.9539 22.0207 61.0451C21.0656 60.1369 21.0279 58.6252 21.9367 57.6701L29.0539 50.1969H11.705V75.2351H47.5947V20.3722H11.705V45.4098H29.0408L21.9367 37.9769Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_135_385_2">
                    <rect width="93" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
